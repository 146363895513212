import moment from "moment";

const initalState = {
  language: "English",
  portfolioId: 0,
  clinicianId: 0,
  status: "All Status",
  fromDate: moment()?.subtract(14, "days"),
  toDate: moment()?.add(14, "days"),
  activeTab: "Portfolio"
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "UPDATE_LOGGEDINUSER": return { ...state, loggedInUserId: action?.payload };
    case "CHANGESTATE":
      return {
        ...state,
        ...action?.payload,
      };

    default:
      return state;
  }
};

export { reducer };